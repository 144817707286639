/**
 * @description 公共配置文件
 */

const setting = {
    // 指定后端服务器IP和端口
    target: "http://47.93.97.124:8089/",
    //
    loginAPi:"http://47.93.97.124:8089/login",
    //
    hd_loginAPI:"http://114.55.141.197:8088/AndroidItem/"
}
export default setting